import React from "react"
import { Link } from "gatsby"
import { ParallaxProvider, Parallax } from 'react-scroll-parallax';
import img_usluge_1 from '../images/usluge_img_1.jpg'
import img_partneri from '../images/partneri_img.png'
import img_novosti from '../images/novosti_img_1.jpg'
import Layout from "../components/Layout"
import Image from "../components/image"
import SEO from "../components/seo"
import Swiper from 'react-id-swiper';
import img_logo_small from '../images/eding_logo_small.svg'

class IndexPage extends React.Component{
    constructor(props) {
        super(props);
        this.unsubscribe = null;
    
        this.state = {
            showLocation1: '',
            showLocation2: '',
            showLocation3: '',
            showLocation4: '',
        }
        this.toggleLocation1 = this.toggleLocation1.bind(this);
        this.toggleLocation2 = this.toggleLocation2.bind(this);
        this.toggleLocation3 = this.toggleLocation3.bind(this);
        this.toggleLocation4 = this.toggleLocation4.bind(this);
    }
        //Function: show popup box after adding new product in cart
        toggleLocation1 () {
            if (this.state.showLocation1 === '') {
                this.setState({
                    showLocation1: 'show'
                })
            }     
            else{
                this.setState({
                    showLocation1: ''
                }) 
               }
        }

        //Function: show popup box after adding new product in cart
        toggleLocation2 () {
            if (this.state.showLocation2 === '') {
                this.setState({
                    showLocation2: 'show'
                })
            }     
            else{
                this.setState({
                    showLocation2: ''
                }) 
               }
        }

        //Function: show popup box after adding new product in cart
        toggleLocation3 () {
            if (this.state.showLocation3 === '') {
                this.setState({
                    showLocation3: 'show'
                })
            }     
            else{
                this.setState({
                    showLocation3: ''
                }) 
               }
        }

         //Function: show popup box after adding new product in cart
         toggleLocation4 () {
            if (this.state.showLocation4 === '') {
                this.setState({
                    showLocation4: 'show'
                })
            }     
            else{
                this.setState({
                    showLocation4: ''
                }) 
               }
        }
    render(){
        const params = {
            slidesPerView: 10,
            spaceBetween: 30,
            slidesPerGroup: 10,
            loop: true,
            loopFillGroupWithBlank: false,
            pagination: {
                el: '.swiper-pagination',
                clickable: true,
            },
            autoplay: {
                delay: 2500,
                disableOnInteraction: false
            },
            breakpoints: {
                0: {
                  slidesPerView: 3,
                  spaceBetween:10,
                  slidesPerGroup: 3,
                  centeredSlides: true,
                },
                768: {
                  slidesPerView: 6,
                  slidesPerGroup: 6,
                },
                1024: {
                  slidesPerView: 8,
                  slidesPerGroup: 8,
                },
              },
          }
        let news_posts = [], partners = [];
       const posts = this.props.data.allWordpressPost.edges;
       posts.forEach(post => {
           if(post.node.categories[0].wordpress_id === 4){
               news_posts.push(post);
           }
           else if(post.node.categories[0].wordpress_id === 5){
               partners.push(post);
           }
       });
        const featuredPost = news_posts[0]
        const post1 = news_posts[1]
        const post2 = news_posts[2]
        const post3 = news_posts[3]
        const homepageData = this.props.data.homepage.edges[0].node.acf;
        const locationsData = this.props.data.locations.edges[0].node.acf;
        return(
  <Layout>
  <SEO title="Početna" keywords={[`telekomunikacije`, `inžinjering`]} />
        <ParallaxProvider>
            <Parallax
                offsetYMax={20}
                offsetYMin={-24}
                slowerScrollRate
                tag="figure"
            >
        <section id="_hero" className="hero is-info" style={{ backgroundImage: `url(${homepageData.pocetna_slika.localFile.childImageSharp.resolutions.src})` }}>
            <div className="hero-body">
                <div className="container">
                    <span className="_subtitle">O nama</span>
                    <h1 className="title" dangerouslySetInnerHTML={{__html: homepageData.istaknuti_slogan }}  />
                    <Link to="/o-nama" className="_btnMore">Više <span className="icon-next"></span></Link>
                </div>
            </div>
        </section>
            </Parallax>
            </ParallaxProvider>

        <section id="_first" className="section">
            <div className="container">
                <div className="columns">
                    <div className="column">
                        <div className="_innerBox">
                            <span className="_subtitle">Naša misija</span>
                            <h2 className="title"  dangerouslySetInnerHTML={{__html: homepageData.misija_tekst }} />
                            {/*<p className="content">
                                Misija Eding Telecom-a je da konstantno postiže profitabilan rast kroz pouzdanost, efikasnost, kompetentnost i kvalitet pružanjem najsavremenijih rješenja u oblasti telekomunikacija.
                            </p>*/}
                        </div>
                    </div>
                </div>
            </div>
            
          {/*  <ParallaxProvider>
            <Parallax
                className="custom-class"
                offsetXMax={70}
                offsetXMin={-10}
                slowerScrollRate
                tag="figure"
            >
                <span className="_slogan">ways to connect</span>
            </Parallax>
          </ParallaxProvider> */}
        </section>

        <section id="_2nd" className="section">
            <div className="container">
                <div className="columns">
                    <div className="column is-6">
                        <div className="_innerBox">
                            <span className="_subtitle">Usluge</span>
                            <h3 className="title"  dangerouslySetInnerHTML={{__html: homepageData.usluge_naslov }} />
                            <div className="content"  dangerouslySetInnerHTML={{__html: homepageData.usluge_tekst }} />
                        </div>
                    </div>
                    <div className="column is-6">
                        <img  src={homepageData.usluge_slika.localFile.childImageSharp.resolutions.src} alt="EDING Telecom - Naše usluge"/>
                    </div>
                </div>
            </div>
        </section>

        {/*<section id="_lists" className="section">
            <div className="container is-fluid">
                <div className="columns">
                    <div className="column">
                        <div className="_headCategory">
                            <Link to="/" className="_btnMore">INŽINJERING I <br/>PROJEKTOVANJE <span className="icon-next"></span></Link>
                        </div>
                        <div className="menu">
                            <ul className="menu-list">
                                <li><Link to="/">Telekomunikacije</Link></li>
                                <li><Link to="/">Energetika i upravljanje</Link></li>
                                <li><Link to="/">Građevina</Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className="column">
                        <div className="_headCategory">
                            <Link to="/" className="_btnMore">TELEKOMUNIKACIJE <span className="icon-next"></span></Link>
                        </div>
                        <div className="menu">
                            <ul className="menu-list">
                                <li><Link to="/">Radio i mikrotalasna mreža</Link></li>
                                <li><Link to="/">Opričke mreže</Link></li>
                                <li><Link to="/">4G/5G mreže</Link></li>
                                <li><Link to="/">Data CENRI</Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className="column">
                        <div className="_headCategory">
                            <Link to="/" className="_btnMore">ENERGETIKA I <br/>UPRAVLJANJE <span className="icon-next"></span></Link>
                        </div>
                        <div className="menu">
                            <ul className="menu-list">
                                <li><Link to="/">Infrastruktura</Link></li>
                                <li><Link to="/">Industrija</Link></li>
                                <li><Link to="/">Poslovno-stambeni prostori</Link></li>
                                <li><Link to="/">Ispitivanja u elektroenergetici</Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className="column">
                        <div className="_headCategory">
                            <Link to="/" className="_btnMore">MAŠINSKO- <br/>GRAĐEVINSKI <span className="icon-next"></span></Link>
                        </div>
                        <div className="menu">
                            <ul className="menu-list">
                                <li><Link to="/">Obrada metala</Link></li>
                                <li><Link to="/">Proizvodnja mašinskih konstrukcija</Link></li>
                                <li><Link to="/">Građevinski radovi</Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>*/}

    <section id="_news" className="section">
            <div className="container">
                <div className="columns">
                    <div className="column">
                        <div className="__innerContainer">
                            <span className="_subtitle">Novosti</span>
                            <div className="columns is-gapless">
                                <div className="column">
                                    <div className="_innerBox1">
                                        <div className="_image">
                                            <img 
                                    src={featuredPost.node.featured_media.localFile.childImageSharp.resolutions.src}
                                    width={featuredPost.node.featured_media.localFile.childImageSharp.resolutions.width}
                                    height={featuredPost.node.featured_media.localFile.childImageSharp.resolutions.height}  alt={featuredPost.node.title} />
                                        </div>
                                        <div className="_featuredNews">
                                            <div className="_inner">
                                                <div className="box">
                                                <h3 dangerouslySetInnerHTML={{__html: featuredPost.node.title }} className="title is-2"/>
                                                    <div className="content" dangerouslySetInnerHTML={{__html: featuredPost.node.excerpt}}  />
                                                    <Link to={featuredPost.node.slug} className="_btnMore">Više <span className="icon-next"></span></Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="column">
                                    <div className="_innerBox2">
                                        <div className="_box">
                                        <Link to={post1.node.slug}>
                                        <h2 dangerouslySetInnerHTML={{__html: post1.node.title }} className="title is-3"/>
                                        </Link>
                                        <div className="content" dangerouslySetInnerHTML={{__html: post1.node.excerpt}} />
                                        <Link to={post1.node.slug} className="_btnStyle1">Više</Link>
                                        </div>

                                        <div className="_box">
                                        <Link to={post2.node.slug}>
                                        <h2 dangerouslySetInnerHTML={{__html: post2.node.title }} className="title is-3"/>
                                        </Link>
                                        <div className="content" dangerouslySetInnerHTML={{__html: post2.node.excerpt}} />
                                        <Link to={post2.node.slug} className="_btnStyle1">Više</Link>
                                        </div>

                                        <div className="_box">
                                        <Link to={post3.node.slug}>
                                        <h2 dangerouslySetInnerHTML={{__html: post3.node.title }} className="title is-3"/>
                                        </Link>
                                        <div className="content" dangerouslySetInnerHTML={{__html: post3.node.excerpt}} />
                                        <Link to={post3.node.slug} className="_btnStyle1">Više</Link>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                       <div className="button-area">
                        <Link to="/novosti" className="button _btnHeader _btnHome"><span>Više novosti</span></Link>
                       </div>
                    </div>
                </div>
            </div>
        </section>  
        <section id="locations" className="section locations">
            <div className="container">
                <span className="_subtitle">LOKACIJE</span>
                <h4 className="title is-1">Imamo predstavništva i u drugim zemljama.</h4>
                <div className="content columns is-multiline">
                {locationsData.lokacija_1_drzava !== "" && (
                   <div className="column">
                    <span id="l_1" className="l-name" onClick={this.toggleLocation1.bind(this)}>{locationsData.lokacija_1_drzava}</span>
                    <div id="l_d_1" className={"data " + this.state.showLocation1}>
                        <div className="title">
                            <img src={img_logo_small} alt="EDING Telecom"/>
                            <div className="l-title" dangerouslySetInnerHTML={{__html: locationsData.lokacija_1_naziv }}/>
                        </div>
                       {/* <div className="info">
                        {locationsData.lokacija_1_telefon !== "" && (<p>                                                      
                                t: <a href={"tel:"+locationsData.lokacija_1_telefon}>{locationsData.lokacija_1_telefon}</a>
                            </p>
                            )}
                            {locationsData.lokacija_1_fax !== "" && (<p>                                                      
                                f: {locationsData.lokacija_1_fax}
                            </p>
                            )}
                            </div>*/}
                    </div>
                </div>
                    )}
                {locationsData.lokacija_2_drzava !== "" && (
                    <div className="column">
                    <span id="l_2" className="l-name" onClick={this.toggleLocation2.bind(this)}>{locationsData.lokacija_2_drzava}</span>
                    <div id="l_d_2" className={"data " + this.state.showLocation2}>
                        <div className="title">
                            <img src={img_logo_small} alt="EDING Telecom"/>
                            <div className="l-title" dangerouslySetInnerHTML={{__html: locationsData.lokacija_2_naziv }}/>
                        </div>
                       {/* <div className="info">
                        {locationsData.lokacija_2_telefon !== "" && (<p>                                                      
                                t: <a href={"tel:"+locationsData.lokacija_2_telefon}>{locationsData.lokacija_2_telefon}</a>
                            </p>
                            )}
                            {locationsData.lokacija_2_fax !== "" && (<p>                                                      
                                f: {locationsData.lokacija_2_fax}
                            </p>
                            )}
                        </div>*/}
                    </div>
                    </div>
                )}
                {locationsData.lokacija_3_drzava !== "" && (
                    <div className="column">
                    <span id="l_3" className="l-name" onClick={this.toggleLocation3.bind(this)}>{locationsData.lokacija_3_drzava}</span>
                    <div id="l_d_3" className={"data " + this.state.showLocation3}>
                        <div className="title">
                            <img src={img_logo_small} alt="EDING Telecom"/>
                            <div className="l-title" dangerouslySetInnerHTML={{__html: locationsData.lokacija_3_naziv }}/>
                        </div>
                       {/* <div className="info">
                        {locationsData.lokacija_3_telefon !== "" && (<p>                                                      
                                t: <a href={"tel:"+locationsData.lokacija_3_telefon}>{locationsData.lokacija_3_telefon}</a>
                            </p>
                            )}
                            {locationsData.lokacija_3_fax !== "" && (<p>                                                      
                                f: {locationsData.lokacija_3_fax}
                            </p>
                            )}
                        </div>*/}
                    </div>
                    </div>
                )}
                {locationsData.lokacija_4_drzava !== "" && (
                    <div className="column">
                    <span id="l_4" className="l-name" onClick={this.toggleLocation4.bind(this)}>{locationsData.lokacija_4_drzava}</span>
                    <div id="l_d_4" className={"data " + this.state.showLocation4}>
                        <div className="title">
                            <img src={img_logo_small} alt="EDING Telecom"/>
                            <div className="l-title" dangerouslySetInnerHTML={{__html: locationsData.lokacija_4_naziv }}/>
                        </div>
                        {/*<div className="info">
                        {locationsData.lokacija_4_telefon !== "" && (<p>                                                      
                                t: <a href={"tel:"+locationsData.lokacija_4_telefon}>{locationsData.lokacija_4_telefon}</a>
                            </p>
                            )}
                            {locationsData.lokacija_4_fax !== "" && (<p>                                                      
                                f: {locationsData.lokacija_4_fax}
                            </p>
                            )}
                        </div>*/}
                    </div>
                    </div>
                )}
                </div>
            </div>
        </section>
        <section id="_news" className="section">
            <div id="_partners" className="container short">
                <div className="columns is-multiline">
                <div className="column is-mobile">
                        <div className="_innerPartner">
                            <div className="_inner">
                                <span className="_subtitle">Naši partneri</span>
                                <h4 className="title is-1"  dangerouslySetInnerHTML={{__html: homepageData.partneri_tekst }} />
                            </div>
                        </div>
                    </div>
                    <div className="column is-desktop is-12">
                        <div className="_innerPartner">
                            <div className="_inner">
                                <span className="_subtitle">Naši partneri</span>
                                <h4 className="title is-1"  dangerouslySetInnerHTML={{__html: homepageData.partneri_tekst }} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="_partners" className="container long is-fluid">
                <div className="columns is-multiline">
                    <div className="column is-12 slider">
                    <Swiper {...params}>
                        {partners.map(partner => (
                    <div className="single-partner"><a href={partner.node.acf.link} target="_blank"><img src={partner.node.featured_media.localFile.childImageSharp.resolutions.src} alt={partner.node.title} title={partner.node.title} /></a></div>
                        ))}
                    </Swiper>

                       {/* <div className="_partnerImg">
                        <img src={homepageData.partneri_slika.localFile.childImageSharp.resolutions.src} alt="EDING Telecom - Naši partneri" />
                        </div>*/}
                    </div>
                    
                </div>
            </div>
            </section>
    </Layout>
            )
        }
    }

export default IndexPage;

export const pageQuery = graphql`
    query postsQueryHome{
        allWordpressPost{
            edges{
                node{
                    id
                    title
                    excerpt
                    slug
                    date(formatString: "MMMM DD, YYYY")
                    categories {
                        wordpress_id
                    }
                     acf{
                        link
                    }
                    featured_media{
                        localFile{
                            childImageSharp{
                                resolutions(width:800, height:400){
                                    src
                                    width
                                    height
                                }
                            }
                        }
                    }
                }
            }
        }

        homepage: allWordpressPage(filter: {slug: {eq: "pocetna"}}) {
            edges {
            node {
                id
                acf {
                pocetna_slika
                {
                    localFile{
                            childImageSharp{
                                resolutions(width:1920, height:1080){
                                    src
                                    width
                                    height
                                }
                            }
                        }
                }
                istaknuti_slogan
                misija_tekst
                usluge_naslov
                usluge_tekst
                usluge_slika{
                    localFile{
                            childImageSharp{
                                resolutions(width:1004, height:501){
                                    src
                                    width
                                    height
                                }
                            }
                        }
                }
                partneri_tekst
                partneri_slika{
                    localFile{
                            childImageSharp{
                                resolutions(width:984, height:326){
                                    src
                                    width
                                    height
                                }
                            }
                        }
                    }
                }
            }
            }
        }
        locations: allWordpressPage(filter: {slug: {eq: "postavke-stranice"}}) {
            edges {
            node {
                id
                acf {
                    lokacija_1_drzava
                    lokacija_2_drzava
                    lokacija_3_drzava
                    lokacija_4_drzava
                    lokacija_1_naziv
                    lokacija_2_naziv
                    lokacija_3_naziv
                    lokacija_4_naziv
                    lokacija_1_adresa
                    lokacija_2_adresa
                    lokacija_3_adresa
                    lokacija_4_adresa
                    lokacija_1_telefon
                    lokacija_2_telefon
                    lokacija_3_telefon
                    lokacija_4_telefon
                    lokacija_1_fax
                    lokacija_2_fax
                    lokacija_3_fax
                    lokacija_4_fax
                }
            }
            }
        }

    }
`